
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import Dropzone from 'react-dropzone';

const API_ENDPOINT = 'https://admin.rossen.si:61004';
function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class File extends Component {
    constructor(props) {
        super(props);
        this.dropzone = React.createRef()
        this.removeImage = this.removeImage.bind(this);

        this.state = {
        };
    }




    removeImage() {
        this.props.socketIOClient.emit("adminDeleteFile", {url: this.props.value});

        if (this.props.multilang){
            let value = this.props.value;
            delete value[this.props.lang];
            this.props.onChange(value);
            this.forceUpdate();
        }else {
            this.props.onChange(null);
            this.forceUpdate();

        }
    }

    componentDidUpdate(prevProps){
        if (!prevProps.value && this.props.value && this.props.multilang && typeof this.props.value == 'string'){
            let value = {};
            value[this.props.lang] = this.props.value;
            this.props.onChange(value);
        }
    }






    render() {



        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <div className="file-drop" ref={(ref) => this.dropzone = ref}>


                    <Dropzone
                        onDrop={async (imageFiles) => {

                            this.setState({
                                _uploading: true
                            }, () => {
                                let formData = new FormData();
                                formData.append('file', imageFiles[0]);
                    
                                fetch(API_ENDPOINT + '/data/upload-image', {
                                    method: 'POST',
                                    headers: {
                                        Accept: 'application/json',
                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    
                                    },
                                    body: formData
                                }).then(parseText).then(({ result, status }) => {
                                    if (status != 200) {
                                        this.setState({ _uploading: null })
                                    } else {
                                        if (this.props.multilang){
                                            let value = this.props.value;
                                            if (!value){
                                                value = {};
                                            }

                                            value[this.props.lang] =  'https://rossen.si' + result;
                                            this.props.onChange(value);

                                        }else {
                                            this.props.onChange('https://rossen.si' + result);
                                        }
                                        this.setState({
                                            _uploading: null
                                        })
                                    }
                                })
                
                            })
                            

                            

                        }}
                        className='dropzone'
                        activeClassName='active-dropzone'
                        multiple={false}>

                        <button type="button"><i className="mdi mdi-file-outline"></i></button>


                    </Dropzone>
                    
                    
                    {((this.props.value && this.props.multilang && this.props.value[this.props.lang]) || (this.props.value && !this.props.multilang)) ?
                        <div className="preview">

                            <img src={this.props.multilang ? this.props.value[this.props.lang] : this.props.value} />
                            <div className='options'>
                                <i className="mdi mdi-delete" onClick={() => this.removeImage()}></i>
                            </div>

                        </div> : null

                    }

                    { this.state._uploading && 
                    <div className="spinner-container">
                        <div className="spinner"></div>
                    </div>
                    }
                </div>









            </div>
        );
    }
}

export default File;